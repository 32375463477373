import translation from './translation.json';
import courseProgramm from './courseProgramm.json';
import courseProgrammDetalis from './courseProgrammDetalis.json';
import reviews from './reviews.json';
import faq from './faq.json';
import ofertaPage from './ofertaPage.json';

export default {
  ...translation,
  ...courseProgramm,
  ...courseProgrammDetalis,
  ...reviews,
  ...faq,
  ...ofertaPage,
};
