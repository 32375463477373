export const sectionsData = [
  { title: 'title1', count: 7 },
  { title: 'title2', count: 9 },
  { title: 'title3', count: 5 },
  { title: 'title4', count: 10 },
  { title: 'title5', count: 19 },
  { title: 'title6', count: 10 },
  { title: 'title7', count: 57 },
  { title: 'title8', count: 21 },
  { title: 'title9', count: 16 },
  { title: 'title10', count: 5 },
  { title: 'title11', count: 3 },
  { title: 'title12', count: 8 },
  { title: 'title13', count: 7 },
];

export const bankInfo = [
  {
    name: 'name1',
    accountNumber: '3432111917',
    iban: 'UA883220010000026008330173529',
    bankName: 'АКЦІОНЕРНЕ ТОВАРИСТВО "УНІВЕРСАЛ БАНК"',
    link: 'support@resonance.vision',
  },
];
