import React from 'react';
import style from './OfertaPage.module.css';
import { useTranslation, Trans } from 'react-i18next';
import { sectionsData, bankInfo } from './OfertaPageData';

const Section = ({ title, texts, link, link2 }) => {
  return (
    <div className={style.section}>
      <h2 className={style.title}>
        <Trans i18nKey={title} />
      </h2>
      {texts.map((textKey, index) => (
        <p key={index}>
          <Trans
            i18nKey={textKey}
            components={{
              a: (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              ),
              span: (
                <a href={link2} target="_blank" rel="noopener noreferrer">
                  {link2}
                </a>
              ),
            }}
            values={{ link, link2 }}
          />
        </p>
      ))}
    </div>
  );
};

const BankInfo = ({ name, accountNumber, iban, bankName, link }) => {
  const { t } = useTranslation();
  return (
    <div className={style.bankInfo}>
      <p>
        <span>ФОП</span>
        {t(name)}
      </p>
      <div className={style.bank}>{accountNumber}</div>
      <div className={style.bank}>{iban}</div>
      <div className={style.bank}>{bankName}</div>
      <div className={style.bank}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      </div>
    </div>
  );
};

export default function OfertaPage() {
  const link = 'https://course.resonance.vision/oferta6/';
  const link2 = 'support@resonance.vision';

  const sections = sectionsData.map((section, index) => (
    <Section
      key={index}
      title={section.title}
      texts={Array.from(
        { length: section.count },
        (_, i) => `section${index + 1}.text${i + 1}`,
      )}
      link={link}
      link2={link2}
    />
  ));

  const bankInformation = bankInfo.map((bank, index) => (
    <BankInfo key={index} {...bank} />
  ));

  return (
    <div className={style.root}>
      <div className={style.container}>
        <h1>
          <Trans i18nKey="titleMain" />
        </h1>
        <p className={style.underTitle}>
          <Trans i18nKey="underTitle" />
        </p>
        <p className={style.underTitle}>
          <Trans i18nKey="underTitle2" />
        </p>
        <p>
          <Trans
            i18nKey="textUnderTitle"
            components={{
              a: (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              ),
            }}
            values={{ link }}
          />
        </p>
        {sections}
        <div className={style.container14}>
          <h2 className={style.title}>
            <Trans i18nKey="title14" />
          </h2>
          {bankInformation}
        </div>
      </div>
    </div>
  );
}
